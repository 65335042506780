import { Component, Vue } from "vue-property-decorator";
import { IsDefined, IsNotEmpty, MaxLength, validate } from "class-validator";

import { Header,Footer } from "@/layout";
import { APLightbox } from "@/components";
import { ErrorCode, ErrorMessage } from "@/constant";
import { RollcallModel } from "@/models";
import { TeCommon } from "../Common";

export class RollcallForm {
    @IsDefined() @IsNotEmpty({ message: "必填" }) time = '';
    @IsDefined() @IsNotEmpty({ message: "必填" }) cid = '';
    @IsDefined() @IsNotEmpty({ message: "必填" }) sid = '';
    @IsDefined() @IsNotEmpty({ message: "必填" }) clid = '';
    // tid = '';
    @MaxLength(100, { message: "最多輸入100字" }) note = '';
}

@Component<ApTeacherRollcallPaperAddController>({
    components: {
        Header, APLightbox,Footer
    }
})
export default class ApTeacherRollcallPaperAddController extends Vue {
    /*定義data 變數 */
    private doubleClick = false;
    private rollcallForm = new RollcallForm();
    private cityList: object = {};
    private schoolList: object = {};
    private classList: object = {};
    private tid = window.localStorage.getItem('teacher_tid') as string;
    private name = window.localStorage.getItem('teacher_name') as string;
    private token = window.localStorage.getItem('teacherToken') as string;

    private lightbox: { showLB: boolean, errorMsg: string } = {
        showLB: false, 
        errorMsg:  '',
    };

    private errorMsgMap: { [key: string]: string } = {
        time: '',
        cid: '',
        sid: '',
        clid: '',
        note: '',
    };

    public async created() {
        this.cityList = await TeCommon.getTECity(this.tid);//取得該老師所屬班級的所有縣市
    }

    //取得該老師所屬班級之該縣市分校列表
    public async getTESchool(cid: string) {
        this.rollcallForm.sid = '';
        this.rollcallForm.clid = '';
        this.schoolList = await TeCommon.getTESchool(cid, this.tid);
        this.classList = [];
    }

    //取得該老師所屬班級之該分校班級列表
    public async getTEClass(sid: string) {
        this.rollcallForm.clid = '';
        this.classList = await TeCommon.getTEClass(sid, this.tid);
    }

    public async validateAndSubmit() {
        validate(this.rollcallForm, { skipMissingProperties: true }).then(
            async result => {
                if (result.length !== 0) {
                    this.errorMsgMap = { //先清空error message
                        time: '',
                        cid: '',
                        sid: '',
                        clid: '',
                        note: '',
                    };
                    result.forEach((item, index) => {
                        const error = item.constraints as {
                            [key: string]: string;
                        };
                        Object.keys(error).forEach(key => {
                            this.errorMsgMap[item.property] = error[key]; //放入error message
                        });
                    });
                    return;
                }

                if(!this.doubleClick){
                    this.doubleClick = true; 

                    const item = { //要傳入的form參數
                        time : this.rollcallForm.time,
                        sid : this.rollcallForm.sid,
                        clid : this.rollcallForm.clid,
                        tid : this.tid,
                        note : this.rollcallForm.note,
                        token: this.token,
                    }
                    const errNo = await RollcallModel.teAdd(item);
                    switch (errNo) {
                        case ErrorCode.InvalidToken:
                            TeCommon.logout();
                            break;
                        case ErrorCode.Success:
                            this.doubleClick = false;
                            this.$router.push("/teacher/rollcall");
                            break;
                        default:
                            this.lightbox.errorMsg = ErrorMessage[errNo];
                            this.lightbox.showLB = true;
                            this.doubleClick = false;
                            break;
                    }

                }
            }
        )
    }

    private closeLB() {
        this.lightbox.showLB = false;
    }
}